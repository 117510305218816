module.exports = {
  pathPrefix: '/',
  // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: 'Saturn V Test Artifacts',
  // Navigation and Site Title
  siteTitleAlt: 'Saturn V Test Artifacts',
  // Alternative Site title for SEO
  siteHeadline: 'Saturn V Test Artifacts',
  // Headline for schema.org JSONLD
  siteTitleShort: 'Saturn V Test Artifacts',
  // short_name for manifest
  siteUrl: 'https://apollo.likewise.org',
  // Domain of your site. No trailing slash!
  siteLanguage: 'en',
  // Language Tag on <html> element
  siteLogo: '/logo.png',
  // Used for SEO and manifest
  siteDescription: 'Saturn V Test Artifacts',
  author: 'Bill Agee',
  // Author for schema.org JSONLD
  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@obvioso',
  // Twitter Username
  //ogSiteName: 'emma', // Facebook Site Name
  ogLanguage: 'en_US',
  googleAnalyticsID: 'UA-144040266-1',
  // Manifest and Progress color
  themeColor: '#3498DB',
  backgroundColor: '#2b2e3c'
};